var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.OverLay,"variant":"transparent","opacity":"0.99","blur":"5px","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon-controller',{attrs:{"font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" กรุณารอสักครู่... ")])],1)]},proxy:true}])},[_c('b-card',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("ชื่อผู้ใช้ "),_c('small',{staticClass:"text-danger"},[_vm._v("*(ใส่ตัวอักษร หรือตัวเลขอย่างน้อย 2 ตัวอักษร)")])]),_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" "+_vm._s(_vm.UserData.agent_username)+" ")]),_c('b-form-input',{model:{value:(_vm.prename),callback:function ($$v) {_vm.prename=$$v},expression:"prename"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรุณากรอกชื่อผู้ใช้ให้ถูกต้อง' : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("รหัสผ่าน"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.PasswordValue),callback:function ($$v) {_vm.PasswordValue=$$v},expression:"PasswordValue"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[(_vm.PasswordValue)?_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("ยืนยันรหัสผ่าน"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("ชื่อ"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรุณากรอกชื่อให้ถูกต้อง' : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("นามสกุล"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : ''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("เบอร์โทรศัพท์")]),_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("Line ID")]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.line_id),callback:function ($$v) {_vm.line_id=$$v},expression:"line_id"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("ชื่อเว็บไซต์")]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.site),callback:function ($$v) {_vm.site=$$v},expression:"site"}})],1)],1),(_vm.UserData.role === 'agseamless')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("Call back domain"),_c('small',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"lotto_urlcallback","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.lotto_urlcallback),callback:function ($$v) {_vm.lotto_urlcallback=$$v},expression:"lotto_urlcallback"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : ''))])]}}],null,false,2573571600)})],1)],1):_vm._e()],1)],1),_c('hr'),_c('div',{staticClass:"mt-1"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.Overlay},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" บันทึกข้อมูล ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }